import { Injectable } from '@angular/core';
import { Api, User } from '@providers/providers';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoachmarkService {
  constructor(private api: Api, private user: User) {}

  updateCoachmark(coachmark: any): Observable<any> {
    return this.api.put('user', { couchmarks: coachmark }).pipe(
      tap(() => {
        const newCoachmark = { ...this.user.currentUser.couchmarks, ...coachmark };
        this.user.updateUserProperty('couchmarks', newCoachmark);
      }),
      take(1)
    );
  }
}
